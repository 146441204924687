<template>
  <div class="ProductDetailPage pb-l">
    <hero-cmp
      :class="[
        {
          'colorful-bg-with-img insetShadow': lgAndUp && conf.hero_img,
          'colorful-bg': !lgAndUp || !conf.hero_img,
          [conf.hero_bg_override]: conf.hero_bg_override ? true : false,
          [conf.ancestor_color]: !conf.hero_bg_override && conf.ancestor_color
        },
      ]"
    >
      <template v-if="lgAndUp" #image>
        <slot name="product-image" />
      </template>
      <template #type>
        <slot name="product-type" />
      </template>
      <template #title>
        <slot name="product-hero-title" />
      </template>
    </hero-cmp>
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-xl-8 py-xs">
          <slot name="product-breadcrumbs" />
        </div>
      </div>
    </div>
    <side-by-side-cmp
      class="image-left with-carousel animationForTransparentsImgs"
    >
      <template>
        <title-text-cmp class="multiple-cta-block">
          <template #title>
            <slot name="product-title" />
          </template>
          <template #text>
            <slot name="product-description" />
          </template>
          <template #cta>
            <cta-button-cmp-extended
              v-if="conf.product_sheet"
              class="btn-primary"
              :data-url="conf.product_sheet"
            >
              {{ conf.product_sheet_cta_label }}
              <template #after-icon>
                <svg>
                  <use href="#icon-download" />
                </svg>
              </template>
              <template #seo-link>
                <slot name="product-sheet-seo-link" />
              </template>
            </cta-button-cmp-extended>
            <cta-button-cmp-extended
              class="btn-text"
              :data-url="
                override_cta_contact_us
                  ? override_cta_contact_us
                  : contact_us_href
              "
              :data-aria-label="contact_us_label_detail"
            >
              {{ contact_us_label_detail }}
              <template #after-icon>
                <svg>
                  <use href="#icon-arrow-right" />
                </svg>
              </template>
              <template #seo-link>
                <slot name="product-seo-link" />
              </template>
            </cta-button-cmp-extended>
          </template>
        </title-text-cmp>
      </template>
      <template #carousel>
        <slot name="product-images" />
      </template>
    </side-by-side-cmp>
    <focus-items-cmp
      v-if="productTabs.length > 0"
      class="with-tab-menu pt-r"
      :data-active-tab-id="productTabs[0].id"
      :data-tabs="productTabs"
    >
      <template v-if="hasSpecifications" #tab-1-content>
        <focus-items-tab-content-cmp class="with-grid">
          <template #focus-items>
            <icon-text-info-cmp
              v-for="(item, index) of conf.product_specifications"
              :key="`product_specification_${index}`"
              :data-unit-value="item.value"
            >
              <template #icon>
                <svg>
                  <use :href="`#${item.attribute_icon}`" />
                </svg>
              </template>
              <template #unit>{{ item.value_measure_unit }}</template>
              <template #text>{{ item.attribute_label }}</template>
            </icon-text-info-cmp>
          </template>
        </focus-items-tab-content-cmp>
      </template>
      <template v-if="hasNutritions" #tab-2-content>
        <focus-items-nutrition-allergens-cmp
          :data-nutrition-and-allergens-count="nutritionsfullSize.length"
        >
          <template
            v-for="(item, index) in nutritionsfullSize"
            #[`icon-${index+1}`]
          >
            <svg v-if="item.attribute_icon" :key="`icon-${index + 1}`">
              <use :href="`#${item.attribute_icon}`" />
            </svg>
          </template>
          <template
            v-for="(item, index) in nutritionsfullSize"
            #[`label-${index+1}`]
          >
            {{ item.label }}
          </template>
          <template
            v-for="(item, index) in nutritionsfullSize"
            #[`description-${index+1}`]
          >
            <div :key="item.description" v-html="item.description"></div>
          </template>
          <template
            v-for="(item, index) in nutritionsfullSize"
            #[`link-${index+1}`]
          >
            <cta-button-cmp-extended
              v-if="item.cta"
              :key="`${item.cta.name}-${index}`"
              class="btn-text"
              :data-url="item.cta.link"
              :data-target="item.cta.target || null"
              :data-rel="item.cta.rel ? item.cta.rel : null"
              :data-aria-label="item.aria_label"
            >
              {{ item.cta.name }}
              <template #after-icon>
                <svg>
                  <use href="#icon-arrow-up-right" />
                </svg>
              </template>
            </cta-button-cmp-extended>
          </template>
        </focus-items-nutrition-allergens-cmp>
        <focus-items-nutrition-allergens-cmp
          :data-nutrition-and-allergens-count="nutritionsHalfSize.length"
        >
          <template
            v-for="(item, index) in nutritionsHalfSize"
            #[`icon-${index+1}`]
          >
            <svg v-if="item.attribute_icon" :key="`icon-${index + 1}`">
              <use :href="`#${item.attribute_icon}`" />
            </svg>
          </template>
          <template
            v-for="(item, index) in nutritionsHalfSize"
            #[`label-${index+1}`]
          >
            {{ item.label }}
          </template>
          <template
            v-for="(item, index) in nutritionsHalfSize"
            #[`description-${index+1}`]
          >
            <div :key="item.description" v-html="item.description"></div>
          </template>
          <template
            v-for="(item, index) in nutritionsHalfSize"
            #[`link-${index+1}`]
          >
            <cta-button-cmp-extended
              v-if="item.cta"
              :key="`${item.cta.name}-${index}`"
              class="btn-text"
              :data-url="item.cta.link"
              :data-target="item.cta.target || null"
              :data-rel="item.cta.rel ? item.cta.rel : null"
              :data-aria-label="item.aria_label"
            >
              {{ item.cta.name }}
              <template #after-icon>
                <svg>
                  <use href="#icon-arrow-up-right" />
                </svg>
              </template>
            </cta-button-cmp-extended>
          </template>
          <template v-if="conf.nutritional_values" #table>
            <table-cmp class="four-cols-leading">
              <template #seo-table>
                <table>
                  <thead v-if="nutritionalValuesHead">
                    <tr>
                      <th
                        v-for="(label, key) in nutritionalValuesHead"
                        :key="`seo_th_${key}`"
                        v-html="label"
                      />
                    </tr>
                  </thead>
                  <tbody v-if="nutritionalValuesRows">
                    <tr
                      v-for="(row, rowIndex) in nutritionalValuesRows"
                      :key="`seo_tr_${rowIndex}`"
                    >
                      <td
                        v-for="(col, colKey) in row"
                        :key="`seo_td_${rowIndex}_${colKey}`"
                      >
                        {{ col.value }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </template>
              <template v-if="nutritionalValuesHead" #thead>
                <table-row-cmp>
                  <table-cell-cmp
                    v-for="(label, key) in nutritionalValuesHead"
                    :key="`th_${key}`"
                    data-tag="th"
                  >
                    <span v-html="label" />
                  </table-cell-cmp>
                </table-row-cmp>
              </template>
              <template v-if="nutritionalValuesRows" #tbody>
                <table-row-cmp
                  v-for="(row, rowIndex) in nutritionalValuesRows"
                  :key="`tr_${rowIndex}`"
                >
                  <table-cell-cmp
                    v-for="(col, colKey) in row"
                    :key="`td_${rowIndex}_${colKey}`"
                    :class="{ tabbed: col.tabbed }"
                  >
                    {{
                      customDecimalSeparator(
                        col.value,
                        $store.state.config.decimal_separator
                      )
                    }}
                  </table-cell-cmp>
                </table-row-cmp>
              </template>
            </table-cmp>
          </template>
        </focus-items-nutrition-allergens-cmp>
      </template>
      <template v-if="hasBenefits" #tab-3-content>
        <focus-items-tab-content-cmp class="with-grid">
          <template #focus-items>
            <icon-text-info-cmp
              v-for="(benefit, index) in conf.benefits"
              :key="`benefit_${index}`"
            >
              <template #icon>
                <svg>
                  <use :href="`#${benefit.icon}`" />
                </svg>
              </template>
              <template #title>{{ benefit.label }}</template>
            </icon-text-info-cmp>
          </template>
        </focus-items-tab-content-cmp>
      </template>
      <template v-if="hasCookingTimes" #tab-4-content>
        <focus-items-tab-content-cmp class="with-grid">
          <template #focus-items>
            <icon-text-info-cmp
              v-for="(cooking_time, index) in conf.cooking_times"
              :key="`cooking_time_${index}`"
              :data-unit-value="cooking_time.value"
            >
              <template #icon>
                <svg>
                  <use :href="`#${cooking_time.attribute_icon}`" />
                </svg>
              </template>
              <template #title>{{ cooking_time.attribute_label }}</template>
              <template #unit>{{ cooking_time.value_measure_unit }}</template>
              <template #text>
                {{ cooking_time.attribute_description }}
              </template>
            </icon-text-info-cmp>
          </template>
        </focus-items-tab-content-cmp>
      </template>
    </focus-items-cmp>
    <carousel-certifications-cmp>
      <template #carousel>
        <slot name="product-certifications" />
      </template>
    </carousel-certifications-cmp>
    <banner-cmp
      v-if="conf.did_you_know_box_title && conf.did_you_know_box_description"
      class="product-story pt-l"
    >
      <template #image>
        <slot name="banner-image" />
      </template>
      <template>
        <title-text-cmp>
          <template #overtitle>
            <slot name="banner-overtitle" />
          </template>
          <template #text>
            <slot name="banner-text" />
          </template>
        </title-text-cmp>
      </template>
    </banner-cmp>
    <template v-if="inspired_recipes.length">
      <carousel-posts-cmp class="pt-l" :data-disabled-from="1024">
        <template #title-separator>
          <title-separator-cmp data-switch-breakpoint="lg">
            <template v-if="related_recipes_title" #title>
              <slot name="inspired-recipes-title" />
            </template>
            <template v-if="recipes_view_all_url" #cta>
              <cta-button-cmp-extended
                class="btn-text"
                :data-url="recipes_view_all_url"
                :data-aria-label="recipes_view_all_label"
              >
                {{ recipes_view_all_label }}
                <template #after-icon>
                  <svg>
                    <use href="#icon-arrow-right" />
                  </svg>
                </template>
                <template #seo-link>
                  <slot name="recipes-view-all-seo-link" />
                </template>
              </cta-button-cmp-extended>
            </template>
          </title-separator-cmp>
        </template>
        <template #carousel>
          <slot name="inspired-recipes" />
        </template>
      </carousel-posts-cmp>
    </template>
    <carousel-posts-cmp
      v-if="conf.related_products && conf.related_products.length > 0"
      class="pt-l"
      :data-disabled-from="1024"
    >
      <template #title-separator>
        <title-separator-cmp data-switch-breakpoint="lg">
          <template v-if="related_products_title" #title>
            <slot name="related-products-title" />
          </template>
          <template v-if="products_view_all_url" #cta>
            <cta-button-cmp-extended
              class="btn-text"
              :data-url="products_view_all_url"
              :data-aria-label="products_view_all_label"
            >
              {{ products_view_all_label }}
              <template #after-icon>
                <svg>
                  <use href="#icon-arrow-right" />
                </svg>
              </template>
              <template #seo-link>
                <slot name="product-view-all-seo-link" />
              </template>
            </cta-button-cmp-extended>
          </template>
        </title-separator-cmp>
      </template>
      <template #carousel>
        <slot name="related-products" />
      </template>
    </carousel-posts-cmp>
  </div>
</template>

<script>
import { castPropToType } from '@/helpers/cms-support';
import { debug } from '@/helpers/utils';

import { lgAndUp } from '@/helpers/breakpoints';
import { mapState } from 'vuex';
import DidYouKnowBg from '@/img/did_you_know_bg.jpg';
import { customDecimalSeparator } from '@/helpers/utils';

export default {
  name: 'ProductDetailPage',
  props: {
    dataConfig: { type: String, required: true },
  },
  setup() {
    return { castPropToType, lgAndUp, customDecimalSeparator };
  },

  computed: {
    // #fe-point-2
    inspired_recipes() {
      return this.conf?.inspired_recipes?.length
        ? this.conf.inspired_recipes
        : this.conf?.related_recipes?.length
        ? this.conf.related_recipes
        : [];
    },
    /*
      Because of the request of having a full size ingredients items
      in the tab and because handling it in the component with different tempalates was
      toolong, and because we are close to the online, we decided to put the template two
      times removing the table from the first so that you can have full size ingredients
    */
    nutritions() {
      return this.conf.nutrition_and_allergens;
    },
    nutritionsfullSize() {
      if (this.nutritions.length) {
        return this.conf.nutrition_and_allergens.filter((i) => i.is_full_size);
      } else {
        return [];
      }
    },
    nutritionsHalfSize() {
      if (this.nutritions.length) {
        return this.conf.nutrition_and_allergens.filter((i) => !i.is_full_size);
      } else {
        return [];
      }
    },
    hasSpecifications() {
      return this.conf.attribute_categories.find(
        (c) => c.category === 'specification'
      );
    },
    hasNutritions() {
      return this.conf.attribute_categories.find(
        (c) => c.category === 'nutrition'
      );
    },
    hasBenefits() {
      return this.conf.attribute_categories.find(
        (c) => c.category === 'benefits'
      );
    },
    hasCookingTimes() {
      return this.conf.attribute_categories.find(
        (c) => c.category === 'cooking-time'
      );
    },
    ...mapState('config', ['contact_us_href']),
    ...mapState('products', [
      'contact_us_label_detail',
      'related_products_title',
      'products_view_all_url',
      'products_view_all_label',
      'related_recipes_title',
      'recipes_view_all_url',
      'recipes_view_all_label',
      'override_cta_contact_us',
    ]),
    conf() {
      return this.dataConfig
        ? this.castPropToType(this.dataConfig, 'object')
        : null;
    },
    productTabs() {
      const tabs = [];

      if (this.hasSpecifications) {
        tabs.push({ id: '1', label: this.getTabTitle('specification') });
      }
      if (this.hasNutritions) {
        tabs.push({ id: '2', label: this.getTabTitle('nutrition') });
      }
      if (this.hasBenefits) {
        tabs.push({ id: '3', label: this.getTabTitle('benefits') });
      }
      if (this.hasCookingTimes) {
        tabs.push({ id: '4', label: this.getTabTitle('cooking-time') });
      }
      return tabs;
    },

    nutritionalValuesHead() {
      if (this.conf.nutritional_values) {
        const row = this.conf.nutritional_values.find((row) => row.is_header);
        if (row) {
          const { is_header, ...cols } = row;
          Object.keys(cols).forEach((col) => {
            const [v, s] = cols[col].split('|');
            if (s) {
              cols[col] = `${v}<small>${s}</small>`;
            }
          });
          return cols;
        }
      }
      return null;
    },
    nutritionalValuesRows() {
      if (this.conf.nutritional_values) {
        return this.conf.nutritional_values
          .filter((row) => !row.is_header)
          .map((row) => {
            const { is_header, ...cols } = row;
            Object.keys(cols).forEach((col) => {
              let tabbed = false;
              let value = cols[col];
              if (value.indexOf('>>>') !== -1) {
                value = value.replace('>>>', '');
                tabbed = true;
              }
              cols[col] = { tabbed, value };
            });
            return cols;
          });
      }
      return null;
    },
    didYouKnowBg() {
      return DidYouKnowBg;
    },
  },
  mounted() {
    // eslint-disable-next-line
    debug && console.log(this.conf);
  },
  methods: {
    getTabTitle(category) {
      const attrCategory = this.conf.attribute_categories.find(
        (c) => c.category === category
      );
      if (attrCategory) {
        return attrCategory.title;
      }
      return 'Undefined';
    },
  },
};
</script>
